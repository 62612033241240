import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegram, FaXTwitter } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa";
import { IoMailUnread } from "react-icons/io5";
import { FaArrowRight } from "react-icons/fa";

export default function Footer({ isPricingPage }) {
    const [isOpen, setIsOpen] = useState(false);

    const scrollToSection = (e, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <footer className={`pt-0 md:pt-1 pb-10 ${isPricingPage ? '' : 'mt-20'}`}>
    <div className='container mx-auto px-4 sm:px-6 lg:px-8 sm:mt-5 md:mt-10 py-10'>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 leading-8'>
            <div>
                <ul>
                    <li><Link href="#servicios" className='font-bold' onClick={(e) => {
                        scrollToSection(e, 'services');
                        setIsOpen(false);
                    }}>Servicios</Link></li>
                    <li><Link href="#beneficios" className='font-bold' onClick={(e) => {
                        scrollToSection(e, 'benefits');
                        setIsOpen(false);
                    }}>Beneficios</Link></li>
                    <li><Link href="#precios" className='font-bold' onClick={(e) => {
                        scrollToSection(e, 'pricing');
                        setIsOpen(false);
                    }}>Precios</Link></li>
                    <li><Link href="https://clientes.desarrollowebprofesional.com" className='font-bold'>Clientes</Link></li>
                </ul>
            </div>
            <div>
                <ul>
                    <li><Link href="#faqs" className='font-bold' onClick={(e) => {
                        scrollToSection(e, 'faqs');
                        setIsOpen(false);
                    }}>FAQs</Link></li>
                    <li><Link href="/blog" className='font-bold'>Blog</Link></li>
                    <li><Link href="/contacto" className='font-bold'>Contacto</Link></li>
                    <li><Link href="https://clientes.desarrollowebprofesional.com/support" className='font-bold'>Soporte técnico</Link></li>
                </ul>
            </div>
            <div>
                <ul>
                    <li><Link href="#servicios" className='font-bold' onClick={(e) => {
                        scrollToSection(e, 'services');
                        setIsOpen(false);
                    }}>Nuestros Servicios</Link></li>
                    <li><Link href="/desarrollo-de-apis">Desarrollo de Api personalizadas</Link></li>
                    <li><Link href="/redaccion-de-contenidos">Redacción de contenidos</Link></li>
                    <li><Link href="/desarrollo-de-plugins-a-medida">Desarrollo de plugins a medida</Link></li>
                </ul>
            </div>
            <div>
                <ul>
                    <li><Link href="/desarrollo-web">Desarrollo Web</Link></li>
                    <li><Link href="/diseno-web">Diseño Web</Link></li>
                    <li><Link href="/desarrollo-de-aplicaciones-web">Desarrollo de aplicaciones</Link></li>
                    <li><Link href="/e-commerce">E-commerce</Link></li>
                </ul>
            </div>
        </div>
        <div className='socialIcons flex justify-center md:justify-start mt-8 space-x-4'>
            <Link href="https://www.facebook.com/desarrollowebtop" target="_blank" rel="noopener noreferrer" title="Facebook">
                <FaFacebook className="w-6 h-6" />
            </Link>
            <Link href="https://x.com/WebDevMx" target="_blank" rel="noopener noreferrer" title="X">
                <FaXTwitter className="w-6 h-6" />
            </Link>
            <Link href="https://www.instagram.com/desarrollo_web_profesional/" target="_blank" rel="noopener noreferrer" title="Instagram">
                <FaInstagram className="w-6 h-6" />
            </Link>
            <Link href="https://www.linkedin.com/in/desarrollo-web-profesional/" target="_blank" rel="noopener noreferrer" title="Linkedin">
                <FaLinkedin className="w-6 h-6" />
            </Link>
            <Link href="https://wa.me/522228756594" target="_blank" rel="noopener noreferrer" title="Whatsapp">
                <IoLogoWhatsapp className="w-6 h-6" />
            </Link>
        </div>
        <div className="sub-footer flex flex-wrap mt-10 text-center sm:text-left">
            <div className="w-full md:w-1/2 flex flex-wrap items-center justify-center md:justify-start space-y-4">
                <div className="w-full md:w-1/4">
                    <Image 
                        src="/images/profile.webp"
                        alt="Web Development Services"
                        title="Web Development Services"
                        className="footer-logo mx-auto"
                        width={100}
                        height={100}
                    />
                </div>
                <div className="w-full md:w-3/4 text-center md:text-left">
                    <p>Calle 10 Ote 4C, San Andrés Cholula, Puebla, México</p>
                    <p className="flex justify-center md:justify-start">
                        <Link href="tel:+522228756594" className="flex items-center">
                            <FaPhone className="mr-1 text-teal-500" /> 
                            (+52) 222-875-65-94
                        </Link>
                    </p>
                    <p className="flex justify-center md:justify-start">
                        <Link href="/contacto" className="flex items-center">
                            <IoMailUnread className="mr-2 text-teal-500" /> 
                            <b>Contacto</b>
                        </Link>
                    </p>
                </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-col items-center md:items-end space-y-2 mt-4 md:mt-0">
                <div className="flex flex-col md:flex-row md:space-x-4">
                    <Link href="/terminos-y-condiciones" className='flex items-center'>
                        <FaArrowRight className='mr-1 text-teal-500' /> Términos y Condiciones
                    </Link>
                    <Link href="/politica-de-privacidad" className='flex items-center'>
                        <FaArrowRight className='mr-1 text-teal-500' /> Política de Privacidad
                    </Link>
                </div>
                <p className="text-teal-500">Todos los Derechos Reservados {new Date().getFullYear()}</p>
            </div>
        </div>
    </div>
</footer>

    );
}