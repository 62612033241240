import React from 'react';
import MainMenu from './MainMenu';
import Image from 'next/image'
import Link from 'next/link';

export default function Header() {
    return (
        <header className="w-full">
            <div id="header" className="w-full pt-4">
                <div className="header-background"></div>
                <div className="container flex flex-row justify-around mx-auto">
                    <div className="logo flex flex-row items-center mt-5">
                        <div className="logoImage">
                            <Link href="/">
                                <Image
                                    src='/images/logo.webp'
                                    alt='Desarrollo Web Profesional'
                                    title='Desarrollo Web Profesional'
                                    width={480}
                                    height={96}
                                    priority
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="main-menu">
                <MainMenu/>
            </nav>
        </header>
    );
}
