import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { FiMenu } from 'react-icons/fi';
import { IoCodeSharp } from "react-icons/io5";
import { FaArrowUpLong } from "react-icons/fa6";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

const MainMenu = () => {
    const router = useRouter();
    const [activeLink, setActiveLink] = useState('');
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const windowSize = useWindowSize();

    useEffect(() => {
        if (router.asPath.includes('#')) {
            setActiveLink(router.asPath.split('#')[1]);
        } else {
            setActiveLink(router.pathname.split('/')[1]);
        }

        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [router.asPath, router.pathname]);

    const scrollToTop = (e) => {
        e.preventDefault();
        const startPosition = window.pageYOffset;
        const duration = 1000; // Duración de la animación en milisegundos
        let start = null;
    
        const step = (timestamp) => {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            const progressPercentage = Math.min(progress / duration, 1);
            const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            const easedProgress = easeInOutQuad(progressPercentage);
            window.scrollTo(0, startPosition * (1 - easedProgress));
            if (progress < duration) {
                window.requestAnimationFrame(step);
            }
        };
    
        window.requestAnimationFrame(step);
    };

    const scrollToSection = (e, id) => {
        e.preventDefault();
        const element = document.getElementById(id);
        if (element) {
            const targetPosition = element.getBoundingClientRect().top + window.pageYOffset;
            const startPosition = window.pageYOffset;
            const distance = targetPosition - startPosition;
            const duration = 1000; // Duración de la animación en milisegundos
            let start = null;
    
            const step = (timestamp) => {
                if (!start) start = timestamp;
                const progress = timestamp - start;
                const progressPercentage = Math.min(progress / duration, 1);
                const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
                const easedProgress = easeInOutQuad(progressPercentage);
                window.scrollTo(0, startPosition + distance * easedProgress);
                if (progress < duration) {
                    window.requestAnimationFrame(step);
                }
            };
    
            window.requestAnimationFrame(step);
        }
    };

    const handleLinkClick = (e, sectionId, link) => {
        e.preventDefault();
        setActiveLink(sectionId);

        if (router.pathname !== '/') {
            router.push(`/#${sectionId}`);
        } else {
            scrollToSection(e, sectionId);
        }
        setIsOpen(false);
    };

    return (
        <div>
            {windowSize.width <= 1280 && (
                <button className="smartBtn" onClick={() => setIsOpen(!isOpen)}>
                    <FiMenu />
                </button>
            )}
            {(windowSize.width > 1280 || isOpen) && (
                <ul className={`menu ${isOpen ? 'open' : ''}`}>
                    <li>
                        <Link href="#" className="upMenu white" onClick={scrollToTop}>
                            {isScrolled ?
                                <FaArrowUpLong className="icon visible" color="white"/> :
                                <IoCodeSharp className="icon visible" color="white"/>
                            }
                        </Link>
                    </li>
                    <li className={activeLink === 'services' ? 'active' : ''}>
                        <Link href="/#services" onClick={(e) => handleLinkClick(e, 'services')}>Servicios</Link>
                    </li>
                    <li className={activeLink === 'benefits' ? 'active' : ''}>
                        <Link href="/#benefits" onClick={(e) => handleLinkClick(e, 'benefits')}>Beneficios</Link>
                    </li>
                    <li className={activeLink === 'pricing' ? 'active' : ''}>
                        <Link href="/#pricing" onClick={(e) => handleLinkClick(e, 'pricing')}>Precios</Link>
                    </li>
                    <li className={activeLink === 'blog' ? 'active' : ''}>
                        <Link href="/blog">Blog</Link>
                    </li>
                    <li className={activeLink === 'faqs' ? 'active' : ''}>
                        <Link href="/#faqs" onClick={(e) => handleLinkClick(e, 'faqs')}>FAQs</Link>
                    </li>
                    <li className={activeLink === 'contacto' ? 'active' : ''}>
                        <Link href="/contacto">Contacto</Link>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default MainMenu;
