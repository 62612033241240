// components/Layout.js
import Head from 'next/head'
import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { Analytics } from "@vercel/analytics/react";

export default function Layout({ children, title, metaTitle, metaDescription, isPricingPage }) {
    return (
        <div>
            <Analytics/>
            <Head>
                <meta charSet="UTF-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>{title}</title>
                <meta name="title" content={metaTitle} />
                <meta name="description" content={metaDescription} />

                {isPricingPage && (
                    <>
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Basic website",
                                "description": "Your hassle-free ticket to a stunning WordPress website that captivates your audience and propels your small business forward!",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 650,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Businesses",
                                "description": "Crafts tailored online solutions, empowering businesses to thrive in the digital realm with captivating web design and seamless functionality.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 1100,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Online Store",
                                "description": "Propels your business into the digital marketplace, a sophisticated platform to elegantly showcase and effortlessly sell products to a global audience.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 1300,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Maintenance & Support",
                                "description": "Your peace-of-mind partner, WpMonitor ensures your WordPress site stays secure, updated, and optimized for peak performance!",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 25,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Data Migration & Configuration",
                                "description": "Your seamless solution to effortlessly transfer and optimize your data for enhanced performance and functionality.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 20,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                        <script
                            type="application/ld+json"
                            dangerouslySetInnerHTML={{
                            __html: JSON.stringify({
                                "@context": "http://schema.org",
                                "@type": "Product",
                                "name": "Support & Customization",
                                "description": "Your dedicated ally for personalized assistance and tailored enhancements to elevate your digital presence.",
                                "offers": {
                                    "@type": "Offer",
                                    "price": 20,
                                    "priceCurrency": "USD"
                                }
                            })
                            }}
                        />
                    </>
                )}
            </Head>
            <Header />
            <main>
                {children}
            </main>
            
        </div>
    )
}
